import React, {useState} from 'react';
import {graphql, Link, useStaticQuery} from 'gatsby';
import * as navigationStyles from './MainNavigation.module.scss';
import HeaderSearchForm from '../Form/HeaderSearchForm';

export default function MainNavigation(): React.JSX.Element {
  const data = useStaticQuery(graphql`
    query listMainMenuLinks {
      allMenuItems(
        sort: { weight: ASC }
        filter: {
          menu_name: { eq: "main" }
          id: { glob: "menu-items-menu_link_content:*" }
        }
      ) {
        nodes {
          title
          url
        }
      }
    }
  `);

  const [show, setShow] = useState(false);
  const toggle = () => {
    setShow(!show);
  };

  return (
    <nav className={navigationStyles.navigation}>
      <div className="vl-layout">
        <div
          className={
            'vl-grid vl-grid--is-stacked-small vl-grid--align-space-between vl-grid--v-center ' +
            navigationStyles.layout
          }
        >
          <div className={'vl-col--9-12 vl-col--12-12--m ' + (!show ? 'vl-u-hidden--m' : '')}>
            <ul>
              {data.allMenuItems.nodes.map((link, idx) => (
                <li key={idx}>
                  <Link to={link.url} onClick={() => setShow(false)}>{link.title}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="vl-col--3-12 vl-col--12-12--m">
            <div className="vl-grid vl-grid--v-center">
              <div className="vl-col--1-1 vl-col--6-12--m vl-col--3-12--s vl-u-hidden--l">
                <button
                  type="button"
                  className={navigationStyles.menu}
                  onClick={toggle}
                >
                  Menu
                </button>
              </div>
              <div className="vl-col--1-1 vl-col--6-12--m vl-col--9-12--s">
                <HeaderSearchForm/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
